<template>
  <div>
    <NavBar
    style="max-height: 50px;margin-bottom: 10px;"
    :username="currentUserName"
    :pageName="pageName"
    ></NavBar>
    <v-app>
      <div style="background-color: #f5faff; height: 93vh !important;">
        <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height: 50px;" :pageName="pageName"></SideBar>
        </v-col>
          <v-col cols="10" style="overflow:scroll; height:90vh;">
            <div style="margin: 10px 20px 20px 50px">
              <!-- <v-row style="margin-left: 5px"> -->
                <div style="display: flex; flex-direction:row; justify-content: space-between;">
                  <div style="width:30%;" align="left">
                    <span style="font-weight: 800; font-size: 12pt;padding-left:8px ;
                       text-transform: uppercase;   letter-spacing: 1px;">
                       <h3>Specialization Information</h3>
                    </span>
                  </div>

                  <div style="display:flex;">
                     <div >


                    <span
                    style="
                    font-size: 26pt;
                    display: flex;
                    justify-content: space-between;
                    align-items: end;
                    "
                    >

                      <v-btn
                      append
                      rounded
                      color="primary"

                      style="height:35px; margin-top:3vh;"
                      @click="addSpecializationFunction"
                      >

                      <span style="font-size: 18pt;"> + </span>
                      <span style="font-weight: 600;">Add Specialization</span>
                    </v-btn>

              </span>

          </div>
                  </div>
                </div>
                <!-- <span style="font-weight: 800; font-size: 12pt;
                text-transform: uppercase;   letter-spacing: 1px;">
                  <h3>Specialization Information</h3>
                </span>
                <span style=" margin-left: 80%">
                  <div style="width: 20%">
                    <div
                    style="
                    border-style: solid;
                    border-color: white;
                    border-radius: 8px;
                    border-width: 0.1em;
                    "
                    >
                    <br />
                    <br />
                    <span
                    style="
                    font-size: 26pt;
                    display: flex;
                    justify-content: space-between;
                    align-items: end;
                    "
                    >

                      <v-btn
                      append
                      rounded
                      color="primary"

                      style="height:35px;margin-right:30px;"
                      @click="addSpecializationFunction"
                      >

                      <span style="font-size: 18pt;"> + </span>
                      <span style="font-weight: 600;">Add Specialization</span>
                    </v-btn>

              </span>
            </div>
          </div></span
          > -->
        <!-- </v-row> -->

        <div
        v-if="!isLoading"
        style="
        margin-top: 2%;
        height: 500px;
        margin-top: 3%;

        "
        >
        <table class="tableClass"  v-if="specialization_list">
          <thead>
            <tr>
              <th class="text-left">Specialization Name</th>
              <th class="text-left">Description</th>
              <th class="text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
            v-for="specialization in specialization_list"
            :key="specialization._id"
            >
            <td style="width:10%;padding: 5px;"  align="left">
              {{ specialization.specialization_name }}
            </td>
            <td style="width:80%;padding: 5px;" align="left">
              {{ specialization.description }}
            </td>
            <td style="width:10%;" align="left">
              <div  style="display:flex; flex-direction:row;">
              <a style="margin:5px;"
              class="btnClass"
              depressed
              icon
              @click="editSpecialization(specialization._id)"
              >
              <img
              src="https://s3iconimages.mymedicine.com.mm/edit.svg"
              />
            </a>
            <a style="margin:5px;padding-right:5px" v-show="false"
            class="btnClass"
            depressed
            icon
            @click="openDialogDeleteSpecializationModal(specialization._id)"
            >
            <img
            src="https://s3iconimages.mymedicine.com.mm/delete.svg"
            />
          </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div v-else>
    <p>
      No specialization Found !
    </p>
  </div>
  <div v-if="pagination_list > 1">
   <v-pagination
   circle
      v-model="page"
      :length="pagination_list"
      :total-visible="7"
      @input="findPage(page)"
    ></v-pagination>
  </div>
</div>
 <v-progress-circular
      indeterminate
      color="primary"
      v-else
    ></v-progress-circular>
</div>
</v-col>
</v-row>
</div>

<div>
  <v-row justify="center">
   <v-dialog v-model="deleteSpecializationModal" persistent max-width="290">
     <v-card>
      <v-card-title style="word-break:normal">
        Do you want to delete the specialization ?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed
        color="primary" @click="deleteSpecialization">
        Yes
      </v-btn>
      <v-btn depressed
      color="primary" @click="closeDialogDeleteSpecializationModal">
      No
    </v-btn>
  </v-card-actions>
</v-card>
</v-dialog>
</v-row>
</div>


</v-app>
</div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
  name: "CustomerHome",
  data() {
    return {
      specialization_list: "",
      user_id: "",
      currentUser: "",
      currentUserName: "",
      pageName: "",
      deleteSpecializationModal: false,
      selectedSpecialization : "",
      isLoading : false,
      pagination_list:"",
      page:1,
    };
  },
  mounted() {
    this.pageName = "ViewSpecialization";
    this.currentUser = this.$cookies.get("token");

    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var data = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        page:1,
        limit:10,

      };
      this.isLoading = true;
      axios_auth_instance_admin.post("/adminViewSpecializationPage", data)
      .then((successResponse) => {
         this.isLoading = false;
        this.currentUserName = successResponse.data.admin_data.admin_name;
        this.specialization_list = successResponse.data.specialization_data.docs;
        this.pagination_list = successResponse.data.specialization_data.totalPages;
      })
      .catch((adminViewSpecializationPageError) => {
         this.isLoading = false;
        handleError(adminViewSpecializationPageError, this.$router, 'adminViewSpecializationPageError', 'AdminLogin', 'token');
      });
    }
  },
  components: {
    NavBar,SideBar
  },
  methods: {
      findPage(pageNum) {
      this.isLoading = true;
      var getPageData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        page: pageNum,
        limit: 10,
        };
      axios_auth_instance_admin
      .post("/adminViewSpecializationPage",
        getPageData
        )
      .then((viewCurrentDateResponse) => {
        this.specialization_list = viewCurrentDateResponse.data.specialization_data.docs;
        this.pagination_list = viewCurrentDateResponse.data.specialization_data.totalPages;
        this.isLoading = false;
       })
      .catch((viewCurrentDateError) => {
        this.isLoading = false;
        handleError(viewCurrentDateError, this.$router, 'viewCurrentDateError', 'AdminLogin', 'token');
      });
    },
    addSpecializationFunction() {
      this.$router.push({
        name: "createSpecialization",
      });
    },
    openDialogDeleteSpecializationModal(specializationId){
      this.deleteSpecializationModal = true;
      this.selectedSpecialization = specializationId
    },
    closeDialogDeleteSpecializationModal(){
      this.deleteSpecializationModal = false;
    },

    gotoLoginPage() {
      var deleteData = {
        idToken: this.currentUser,
      };
      axios
      .post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken", deleteData)
      .then((deleteResponse) => {
        this.$cookies.remove("token");
      })
      .catch((error) => {
      });
      localStorage.clear();
      this.$router.push({
        name: "AdminLogin",
      });
    },
    editSpecialization(specialization_id) {
      this.$router.push({
        path: "editspecialization/" + specialization_id,
      });
    },
    deleteSpecialization(){
      var deleteSpecializationBody = {
        "specializationId"  : this.selectedSpecialization,
        "token" : this.currentUser,
        "typeOfUser" : "ADMIN"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/deleteSpecialization",deleteSpecializationBody)
      .then((deleteSpecializationResponse) =>{
        this.deleteSpecializationModal = false;
        alert("Specialization deleted");
        this.$router.go();

      })
      .catch((deleteSpecializationError)=>{
       if(deleteSpecializationError.response.status == 401){
         window.alert("Sorry !! You are unauthorized !!");
         this.$router.push({
          name : "AdminLogin"
        });
       }
       else{
         window.alert("Specialization delete failed");
       }
     })
    },
  }
}
</script>

<style>
td,
th {
  border: 0px;
}
td {
   padding: 2px;
  font-size: 8pt;
  font-weight: 700;
  text-align: left;
  padding-left: 5px;

}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 500;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 40px;
  padding-left: 5px;
   text-align: left;
}
tr {
  border-bottom: #d4efff 1px solid !important;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
  /* margin-left: 10px; */
}
table > tr > td:last-child {
  white-space: nowrap;
}
.btnClass {
  background-color: transparent;
}
/* width */
::-webkit-scrollbar {
  width: 2  px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#facebook{
  display: none;
}
</style>